exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-ideologie-mdx": () => import("./../../../src/pages/ideologie.mdx" /* webpackChunkName: "component---src-pages-ideologie-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-komunismus-v-cesku-mdx": () => import("./../../../src/pages/komunismus-v-cesku.mdx" /* webpackChunkName: "component---src-pages-komunismus-v-cesku-mdx" */),
  "component---src-pages-porevolucni-kscm-mdx": () => import("./../../../src/pages/porevolucni-kscm.mdx" /* webpackChunkName: "component---src-pages-porevolucni-kscm-mdx" */),
  "component---src-pages-predstavitele-kscm-mdx": () => import("./../../../src/pages/predstavitele-kscm.mdx" /* webpackChunkName: "component---src-pages-predstavitele-kscm-mdx" */)
}

